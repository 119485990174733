import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";

import BannerInicio from "../../component/section/mios/BannerInicio";
import EquiposInicio from "../../component/section/mios/EquiposInicio";
import NoticiaInicio from "../../component/section/mios/Noticiainicio";
import ProximaSede from "../../component/section/mios/ProximaSede";
import PrimerosClasificados from "../../component/section/mios/PrimerosClasificacion";
import PatrocinadoresInicio from "../../component/section/mios/PatrocinadoresInicio";

class Inicio extends Component {
  render() {
    const styles = {
      gridContainer: {
        display: "grid",
        gridTemplateColumns: "2fr 1fr", // Dos columnas para pantallas grandes
        gap: "20px", // Espacio uniforme entre los elementos
        margin: "20px 0",
      },
      rightColumn: {
        display: "flex",
        flexDirection: "column",
        gap: "20px", // Espacio uniforme entre "ProximaSede" y "PrimerosClasificados"
      },
      gridItem: {
        borderRadius: "10px",
        overflow: "hidden",
      },
    };

    return (
      <Fragment>
        <Header />
        <BannerInicio />
        <EquiposInicio />

        <div className="grid-container" style={styles.gridContainer}>
          <div className="grid-item" style={styles.gridItem}>
            <NoticiaInicio />
          </div>

          <div className="right-column" style={styles.rightColumn}>
            <div className="grid-item" style={styles.gridItem}>
              <ProximaSede />
            </div>
            <div className="grid-item" style={styles.gridItem}>
              <PrimerosClasificados />
            </div>
          </div>
        </div>

        <PatrocinadoresInicio />
        <Footer />

        <style jsx="true">{`
          @media (max-width: 768px) {
            .grid-container {
              display: flex !important; /* Forzamos flexbox en pantallas pequeñas */
              flex-direction: column; 
              gap: 20px; 
              margin: 0 10px; /* Aseguramos que haya espacio lateral */
            }
            .right-column {
              display: flex;
              flex-direction: column; 
              gap: 20px;
            }
            .grid-item {
              width: 100%; 
            }
          }
        `}</style>
      </Fragment>
    );
  }
}

export default Inicio;
