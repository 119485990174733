import { Component } from "react";
import { Link } from "react-router-dom";

const subtitle = "PATROCINADORES";
const title = "LOS PATROCINADORES DE LALIGA3X3";
const btnText = "SABER MÁS";

let SponsorListContent = [
  {
    imgUrl: 'assets/images/patrocinadores/logoMomdel12.png',
    imgAlt: 'Momdel',
    enlace: 'https://momdel.es/',
  },
  {
    imgUrl: 'assets/images/patrocinadores/gigantes.png',
    imgAlt: 'Gigantes',
    enlace: 'https://www.gigantes.com/',
  },
  {
    imgUrl: 'assets/images/patrocinadores/wilson.png',
    imgAlt: 'Wilson',
    enlace: 'https://www.wilson.com/es-es/basketball/basketballs',
  },
];

class PatrocinadoresInicio extends Component {
  render() {
    return (
      <div className="sponsor-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header">
            <p>{subtitle}</p>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row g-5 justify-content-center row-cols-xl-4 row-cols-md-3 row-cols-2">
              {SponsorListContent.map((val, i) => (
                <div className="col" key={i}>
                  <div className="sponsor-item">
                    <div className="sponsor-inner">
                      <div className="sponsor-thumb text-center">
                        <a href={val.enlace} target="_blank" rel="noopener noreferrer">
                          <img
                            src={`${val.imgUrl}`}
                            alt={`${val.imgAlt}`}
                            style={{
                              width: "250px", // Ancho fijo para los logos
                              height: "100px", // Altura fija para los logos
                              objectFit: "contain", // Contiene la imagen sin distorsionarla
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-5">
              <Link to="/patrocinadores" className="default-button">
                <span>{btnText} <i className="icofont-circled-right"></i></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatrocinadoresInicio;
