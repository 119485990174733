import React, { Component } from "react";

const title = "Participa en nuestro sorteo";

class Sort extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      apellido: "",
      correo: "",
      instagram: "",
      aceptado: false,
      enviado: false,
      error: null,
    };
  }

  handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({ [name]: type === "checkbox" ? checked : value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { nombre, apellido, correo, instagram, aceptado } = this.state;

    if (!aceptado) {
      this.setState({ error: "Debes aceptar los términos para participar." });
      return;
    }

    fetch("https://laliga3x3.com/backend/api/sorteo.php", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ nombre, apellido, correo, instagram }),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Error en el envío del formulario");
        return response.json();
      })
      .then(() => {
        this.setState({ enviado: true, error: null });
      })
      .catch((error) => {
        this.setState({ error: error.message });
      });
  };

  render() {
    const { nombre, apellido, correo, instagram, aceptado, enviado, error } = this.state;

    return (
      <section className="sorteo-section">
        <div className="container">
          <div className="section-header text-center">
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            {enviado ? (
              <p className="success-message">¡Gracias por participar! Hemos recibido tu inscripción.</p>
            ) : (
              <form onSubmit={this.handleSubmit} className="sorteo-form">
                <div className="form-group">
                  <label htmlFor="nombre">Nombre:</label>
                  <input
                    id="nombre"
                    type="text"
                    name="nombre"
                    value={nombre}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="apellido">Apellido:</label>
                  <input
                    id="apellido"
                    type="text"
                    name="apellido"
                    value={apellido}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="correo">Correo:</label>
                  <input
                    id="correo"
                    type="email"
                    name="correo"
                    value={correo}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="instagram">Usuario de Instagram:</label>
                  <input
                    id="instagram"
                    type="text"
                    name="instagram"
                    value={instagram}
                    onChange={this.handleChange}
                    required
                  />
                </div>
                <div className="form-group checkbox-group">
                  <input
                    id="aceptado"
                    type="checkbox"
                    name="aceptado"
                    checked={aceptado}
                    onChange={this.handleChange}
                  />
                 <label htmlFor="aceptado">
                    Acepto el tratamiento de datos para informar y gestionar la participación en el sorteo, así como
                    para enviar comunicaciones comerciales. 
                    <br></br>
                    <br></br>
                    La Liga 3x3 y Wilson son el organizador y el responsable del
                    sorteo, respectivamente, por lo que utilizarán sus datos para informar y gestionar la participación
                    en el sorteo y enviar comunicaciones comerciales. Para más información sobre el tratamiento y sus
                    derechos, puede consultar la{" "}
                    <a href="/politicaPrivacidadSorteo" target="_blank">
                      política de privacidad
                    </a> y{" "}
                    <a href="/basesDelSorteo" target="_blank">
                      bases del sorteo
                    </a>.
                  </label>
                </div>
                {error && <p className="error-message">Error: {error}</p>}
                <button type="submit" className="btn-submit">
                  Enviar
                </button>
              </form>
            )}
            <div className="images-section">
              <img
                src="assets/images/patrocinadores/wilson.png"
                alt="Wilson Logo"
                className="sponsor-image"
              />
              <img
                src="assets/images/general/blanco.png"
                alt="Blanco Logo"
                className="sponsor-image"
              />
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .sorteo-section {
            padding: 40px 20px;
          }
          .container {
            max-width: 800px;
            margin: 0 auto;
          }
          .section-header h2 {
            font-size: 2rem;
            margin-bottom: 20px;
          }
          .sorteo-form {
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          }
          .form-group {
            margin-bottom: 15px;
          }
          .form-group label {
            display: block;
            margin-bottom: 5px;
          }
          .form-group input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
          .checkbox-group {
            display: flex;
            align-items: center;
          }
          .checkbox-group label {
            margin-left: 10px;
            font-size: 14px;
          }
          .btn-submit {
            background-color: #efb12f;
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            transition: 0.3s ease;
          }
          .btn-submit:hover {
            background-color: #d19b1d;
          }
          .error-message {
            color: red;
            font-size: 14px;
          }
          .success-message {
            color: green;
            font-size: 16px;
            font-weight: bold;
          }
            .images-section {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
          }

          .sponsor-image {
            max-width: 200px;
            margin: 0 10px;
          }
        `}</style>
      </section>
    );
  }
}

export default Sort;
