import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

class Noticias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticias: [],
    };
  }

  componentDidMount() {
    // Realizar la solicitud a la API
    fetch("https://laliga3x3.com/backend/api/listadoNoticias.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ noticias: data });
      })
      .catch((error) => console.error("Error al obtener las noticias:", error));
  }

  // Función para eliminar etiquetas HTML y limitar el texto a una cantidad de palabras
  limitText = (text, wordLimit) => {
    const plainText = text.replace(/<[^>]+>/g, ""); // Elimina las etiquetas HTML
    const words = plainText.split(" ").slice(0, wordLimit); // Toma las primeras 'wordLimit' palabras
    return words.join(" ") + (words.length >= wordLimit ? "..." : ""); // Agrega "..." si el texto es más largo que el límite
  };

  render() {
    const { noticias } = this.state;

    return (
      <Fragment>
        <Header />
        <PageHeader title={"NOTICIAS"} curPage={"NOTICIAS"} />
        <div className="blog-section padding-top padding-bottom">
          <div className="container">
            <div className="section-wrapper">
              <div className="row g-4 justify-content-center">
                {noticias.map((noticia, i) => (
                  <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="blog-item d-flex flex-column align-items-center">
                      <div className="blog-inner d-flex flex-column">
                        <div className="blog-thumb mb-3 d-flex justify-content-center">
                          <Link to={`/noticia/${noticia.id}`}>
                            <img
                              src={`https://laliga3x3.com/backend/DOCS/noticias/${noticia.imagen1}`}
                              alt={`Imagen de ${noticia.titulo}`}
                              className="w-100"
                              style={{ width: "220px", height: "220px", objectFit: "cover" }}
                            />
                          </Link>
                        </div>
                        <div className="blog-content px-3 py-4 text-center">
                          <Link to={`/noticia/${noticia.id}`}>
                            <h3>{noticia.titulo}</h3>
                          </Link>
                          <div className="meta-post mb-2">
                            <a href="#">{noticia.fecha}</a>
                          </div>
                          <p>{this.limitText(noticia.textoCorto, 20)}</p>
                          <Link to={`/noticia/${noticia.id}`} className="default-button">
                            <span>
                              Leer Más <i className="icofont-circled-right"></i>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Noticias;
