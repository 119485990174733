import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

export const BasesDelSorteo = () => (
  <Fragment>
    <Header />
    <PageHeader title={"BASES DEL SORTEO"} curPage={"BASES DEL SORTEO"} />
    <section className="content-section">
      <div className="container">
        <h3 className="title">BASES DEL SORTEO</h3>
        <p className="paragraph">
        1. Definiciones
Sorteo: se refiere al sorteo “Sorteo [*]”
, organizado por la Asociación Baloncesto 3x3 (La Liga 3x3), bajo los términos
y condiciones desarrollados en las presentes Bases.
Jornada: se refiere a cada uno de los eventos o jornadas que forman parte de la competición organizada por La Liga
3x3.
Página del Sorteo: se refiere al sitio web del Sorteo disponible en la siguiente dirección: [*]
Experiencia o Producto: se refiere a las entradas de partidos de la Primera División masculina de baloncesto de carácter
profesional y ámbito estatal organizada por la ACB, en adelante, LIGA ENDESA) que facilita la marca Wilson a La
Liga 3x3 como marca colaboradora de ACB.
Atención al Cliente: se refiere al correo electrónico laliga3x3@gmail.com
Territorio: se refiere al ámbito territorial donde se desarrollará el Sorteo, que estará limitado al territorio español
peninsular, Islas Baleares, Islas Canarias, Ceuta y Mellilla.
<br></br>
<br></br>
 2. Organizador del Sorteo
La organización del Sorteo corresponde a la Asociación Baloncesto 3x3, conocida como La Liga 3x3, con CIF G-
72614753, situada en la Calle Nicolás Copérnico, núm. 26, C.P. 10.004 (Cáceres). La organización del Sorteo se hará
por encargo de Wilson [*]
<br></br>
<br></br>
3. Objeto del Sorteo
El objeto del Sorteo es la promoción de La Liga 3x3 como balón oficial de 3x3 Wilson[*]
<br></br>
<br></br>
4. Premio
La persona participante en el Sorteo que, de acuerdo con estas Bases, resulte ganadora, obtendrá una (1) entrada doble
para asistir a un partido de la LIGA ENDESA [*].
El Premio no incluye gastos de desplazamiento ni dietas, que correrán a cargo de la persona participante y será su
responsabilidad exclusiva.
Dado que el Premio depende en parte de la organización de la Liga Endesa, algunos de los detalles e información
concreta del Premio, y en particular las entradas, serán gestionadas por parte de Wilson, aunque Wilson encarga a La
Liga 3x3 la gestión del sorteo y comunicación con la persona ganadora por correo electrónico cuando se seleccione a la
persona ganadora y cuando se confirme el calendario. A efectos aclaratorios toda gestión y organización se hará por La
Liga 3x3 por encargo de Wilson -quien aporta las entradas para que La Liga 3x3 las sortee-. ACB no tendrá relación ni
responsabilidad alguna en relación con este Sorteo o con el Premio.
Las personas participantes deben cumplir las condiciones de participación y la mecánica establecidas en estas Bases.
Asimismo, el participante tendrá que respetar las condiciones establecidas por ACB organizadora de la LIGA ENDESA,
siendo responsabilidad de las personas participantes asegurarse de su cumplimiento e idoneidad para sus intereses.
Si la persona participante rechaza el Premio, no se le ofrecerá ninguno alternativo. El Premio es personal e intransferible,
sin posibilidad de reventa, ni de forma gratuita ni onerosa; es decir, el Premio no podrá venderse, regalarse, subastarse
y/o ser objeto de cualquier otra actividad que permita obtener un rendimiento económico a la persona participante
ganador y/o transferirlo a un tercero ajeno. La vulneración de esta obligación supondrá la pérdida del derecho de
redención del Premio, sin que la persona participante ganadora tenga derecho a percibir compensación alguna.
Se seleccionará un total de 1 persona participante ganadora.
Asimismo, se seleccionarán 4 personas participantes en concepto de reserva por si no responde en tiempo y forma la
persona seleccionada en primera instancia.
<br></br>
<br></br>
5. Duración o periodo de vigencia
El período de vigencia del Sorteo comienza el día [*] y finaliza el día [*] (ambos incluidos). Coincidirá con la semana
de Jornada de La Liga 3x3.
<br></br>
<br></br>
6. Condiciones de participación
Los requisitos de participación son los siguientes:
➢ La participación en el Sorteo es gratuita.
➢ Podrán participar personas físicas mayores de edad, residentes en el territorio español. No podrán participar
menores de 18 años de edad, ni personas jurídicas. La identidad de las personas participantes se podrá comprobar
a través de documentos oficiales de identidad (Pasaporte, DNI, carné de conducir, etc.).
➢ Solamente se podrá participar en el Sorteo 1 vez por persona.
La persona participante que incumpla cualquiera de las condiciones dispuestas en las presentes Bases implicará la
anulación de su participación de forma automática. Del mismo modo, cualquier declaración falsa, indicios de identidad
falsa o fraude ocasionará la descalificación inmediata del participante y, en su caso, la obligación de devolver el Premio
si ya le hubiese sido entregado o su valor si ya se hubiera disfrutado.
La aceptación expresa de estas Bases es condición necesaria para la participación en el Sorteo y, en consecuencia, para
poder obtener el Premio que se describe en el Apartado 4 de las mismas. Al registrarse en el Sorteo conforme al
procedimiento indicado en el Apartado 7, se entenderá que se aceptan las Bases. En este sentido, La Liga 3x3 rechazará
cualquier solicitud de participación que no cumpla con las condiciones establecidas en estas Bases.
En caso de cancelación de la Experiencia por el organizador o por cualquier otra causa fuera del control de La Liga 3x3,
La Liga 3x3 podrá cancelar el Sorteo y/u ofrecer un Premio que se considere equivalente. Del mismo modo, en caso de
cancelación o modificación de la LIGA ENDESA por ACB o por cualquier otra causa fuera del control de La Liga 3x3,
ésta podrá cancelar el Sorteo, modificar las fechas, organización o detalles accesorios del Premio para ajustarlo al
calendario, y/o ofrecer un Premio que se considere equivalente, aunque no estará obligado a reemplazarlo pues
dependerá de las circunstancias.
Ni La Liga 3x3 ni Wilson son responsables de la terminación, suspensión o cancelación anticipada de la Experiencia,
de la participación en la misma, y/o de la modificación del calendario (a) por parte de las autoridades correspondientes
(incluyendo, entre otros, los cuerpos y fuerzas de seguridad) o (b) por parte de La Liga 3x3, cuando razones de seguridad
o incidencias fuera de su control así́ lo aconsejen.
<br></br>
<br></br>
7. Mecánica del Sorteo
Para participar, la persona participante tendrá que seguir los siguientes pasos:
<br></br>
1) Seguir a la cuenta de Instagram de La Liga 3x3 (https://www.instagram.com/laliga_3x3/)
<br></br>
2) Dar Like a la publicación del Sorteo
<br></br>
3) Cumplimentar el formulario facilitado a través de un enlace con tu nombre, apellidos, correo electrónico y
usuario de instagram.
Es importante que leas previamente las Bases del Sorteo (estarán publicadas tanto en el sitio web de La Liga
3x3 como en el perfil de Instagram).
Realizar un sorteo durante la disputa de cada jornada de 2 entradas para un partido de la LIGA ENDESA para captar
datos de potenciales clientes
<br></br>
<br></br>
8. Selección de persona ganadora
El día [*] se elegirá a la persona ganadora del Sorteo (en lo sucesivo “Ganadora”) y a [*núm] de personas suplentes
ganadoras conforme a lo indicado en estas Bases.
La selección de persona ganadora y suplente(s) se realizará de forma aleatoria a través de la plataforma Easypromos.
Easypromos es una plataforma que garantiza la transparencia del resultado del sorteo y como prueba de ello emite un
certificado de validez para cada uno de sus sorteos, cuyo resultado es inalterable por el organizador del sorteo, y que
cualquier usuario participante puede solicitar al organizador.
La persona ganadora será contactada a través de correo electrónico y publicado de forma pública en una publicación de
Instagram, con el mismo usuario con el que se registraron para participar en el sorteo.
Si en el plazo de 24 horas desde el contacto, La Liga 3x3 no obtiene respuesta expresa y escrita en respuesta al correo
electrónico por parte de la persona ganadora, se asignará una nueva persona ganadora (el suplente 1), según el orden
establecido por la herramienta EasyPromos, quien contará con el mismo plazo de 24 horas para responder desde el envío
del email por parte de La Liga 3x3.
La persona ganadora podrá renunciar al Premio, pero en ningún caso podrá canjearlo por otro distinto.
<br></br>
<br></br>
9. Atención a la persona participante
En caso de producirse cualquier incidencia en el Sorteo, el participante puede dirigirse al correo de Atención al Cliente
(laliga3x3@gmail.com).
<br></br>
<br></br>
10. Protección de datos
Los datos de carácter personal recabados en el marco del Sorteo serán tratados por La Liga 3x3, que actúa como
Encargado del Tratamiento de datos y Wilson como Responsable del Tratamiento.
El tratamiento de datos de carácter personal será llevado a cabo conforme a lo señalado en la Política de Privacidad de
La Liga 3x3 situada en [*copiar enlace de política para sorteos]
Las finalidades principales del tratamiento son facilitar y gestionar la solicitud de la persona participante para participar
en el Sorteo, la gestión del Sorteo, la comunicación de los ganadores y/o el envío de los Premios, así como para recibir
comunicaciones sobre promoción de productos de Wilson y de La Liga 3x3.
La base de legitimación para el tratamiento de los datos personales será la relación contractual o precontractual
establecida entre las partes (art. 6.1 b) RGPD). La comunicación de los datos personales por parte de La Liga 3x3 a
Wilson a estos efectos por parte de las personas participantes es un requisito necesario para que La Liga 3x3 y Wilson
puedan realizar las finalidades indicadas, de lo contrario no podrá gestionar ni atender la participación de las personas
participantes ni las solicitudes que estos envíen.
Los datos que los Participantes proporcionen podrán ser comunicados y/o cedidos únicamente a Wilson, como
Responsable del Tratamiento, las compañías colaboradoras o proveedoras de servicios necesarios para la gestión del
Sorteo, la comunicación del ganador y el envío y disfrute/gestión de los Premios; es decir, aquellos terceros que tengan
conexión directa y sus prestaciones de servicios sean estrictamente necesarias para la realización del Sorteo.
Las personas participantes garantizan que los datos que proporcionen son verdaderos y correctos. En caso de haber
facilitado datos falsos o inexactos, La Liga 3x3 se reserva el derecho a prohibirle la participación en el Sorteo y/o de
eliminarle como persona ganadora, en su caso. La persona participante garantiza haber obtenido el consentimiento
expreso de su acompañante y que este/a ha aceptado que sus datos personales sean facilitados a [*] con las finalidades
indicadas en el presente documento.
Asimismo, la persona participante autoriza a La Liga 3x3 a reproducir, difundir y publicar su nombre y apellidos en
Internet, medios de prensa escrita, radio, televisión, Internet, blogs y Redes Sociales (es decir, en Youtube, Facebook,
LinkedIn, TikTok, Twitter-X, Instagram y/o cualesquiera otras de naturaleza análoga), que tenga por objeto cualquier
aspecto relativo a su participación en el Sorteo y/o su condición como persona ganadora.
El plazo de conservación de los datos será el necesario para llevar a cabo las acciones relativas al Sorteo mencionadas
en las presentes Bases y hasta que no se derive ninguna responsabilidad para La Liga 3x3, como Encargado del
tratamiento, y/o Wilson, como Responsable del tratamiento.
<br></br>
<br></br>
11. Aceptación de normas
La participación en el Sorteo está supeditada a lectura previa y la aceptación de las presentes Bases, que podrán ser
consultados por las personas participantes antes de su participación. Ninguna persona participante podrá impugnar
ninguna decisión tomada por La Liga 3x3 respecto a la interpretación de estas Bases.
Por el mero hecho de participar en el Sorteo, las personas participantes entienden que aceptan expresamente las presentes
Bases y reconocen estar obligados al cumplimiento de las mismas. Cualquier manifestación en sentido contrario,
implicará la exclusión de dicha persona participante, por lo que se recomienda su lectura antes de participar.
Teniendo en cuenta la legislación fiscal aplicable, los Premios que se entreguen como consecuencia de la participación
en juegos, concursos, rifas o combinaciones aleatorias, estén o no vinculados a la oferta, promoción o venta de
determinados bienes, productos o servicios, están sujetos a retención o ingreso a cuenta, según corresponda. Por lo tanto,
La Liga 3x3 estará obligada a realizar la correspondiente gestión.
<br></br>
<br></br>
12. Legislación aplicable y jurisdicción competente
En lo no previsto en las presentes Bases, así como en la interpretación y resolución de los conflictos que pudieran surgir
como consecuencia de las mismas, será de aplicación la legislación española y las partes se someten a los Juzgados y
Tribunales de la ciudad de Madrid.
        </p>

      <style jsx="true">{`
          /* Contenedor principal */
.content-section {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

/* Contenedor interno */
.container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Título */
.title {
  font-size: 1.8rem;
  color: #004085;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Párrafos */
.paragraph {
  font-size: 1rem;
  color: #333333;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

        `}</style>
      </div>
    </section>
      <Footer />
  </Fragment>
);