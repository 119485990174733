import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

class Patrocinadores extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'PATROCINADORES'} curPage={'PATROCINADORES'} />
                <div className="partner-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section-wrapper">
                            <div className="row g-4">
                                <div className="col-lg-6 col-12">
                                    <div className="partner-list" id="accordionExample">
                                        <div className="row g-4 justify-content-center">
                                            <div className="col-12">
                                                <div className="accordion-item" style={{ marginBottom: '20px' }}>
                                                    <div className="accordion-header" id="headingOne">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                                                            aria-controls="collapseOne">
                                                            <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                                <span className="accor-thumb" style={{ marginRight: '10px' }}>
                                                                    <img src="assets/images/patrocinadores/logoMomdel12.png" alt="partner-thumb" />
                                                                </span>
                                                                <span className="accor-title">Momdel</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div id="collapseOne" className="accordion-collapse collapse"
                                                        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body" style={{ padding: '20px', borderRadius: '8px' }}>
                                                            <p>Nuestro partner web, Momdel Aplicaciones.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="accordion-item" style={{ marginBottom: '20px' }}>
                                                    <div className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true"
                                                            aria-controls="collapseTwo">
                                                            <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                                <span className="accor-thumb" style={{ marginRight: '10px' }}>
                                                                    <img src="assets/images/patrocinadores/wilson.png" alt="partner-thumb" />
                                                                </span>
                                                                <span className="accor-title">Wilson</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                                        aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body" style={{ padding: '20px', borderRadius: '8px' }}>
                                                            <p>Balón oficial.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            {/* Other accordion items */}
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12">
                                    <div className="partner-list" id="accordionExample-2">
                                        <div className="row g-4 justify-content-center">
                                            <div className="col-12">
                                                <div className="accordion-item" style={{ marginBottom: '20px' }}>
                                                    <div className="accordion-header" id="headingFive">
                                                        <button className="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true"
                                                            aria-controls="collapseFive">
                                                            <span className="accor-header-inner d-flex flex-wrap align-items-center">
                                                                <span className="accor-thumb" style={{ marginRight: '10px' }}>
                                                                    <img src="assets/images/patrocinadores/gigantes.png" alt="partner-thumb" />
                                                                </span>
                                                                <span className="accor-title">Gigantes</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div id="collapseFive" className="accordion-collapse collapse"
                                                        aria-labelledby="headingFive" data-bs-parent="#accordionExample-2">
                                                        <div className="accordion-body" style={{ padding: '20px', borderRadius: '8px' }}>
                                                            <p>Nuestro partner digital, Gigantes.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            {/* Other accordion items */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        );
    }
}
 
export default Patrocinadores;
