import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

export const PoliticaPrivacidad = () => (
  <Fragment>
    <Header />
    <PageHeader title={"POLÍTICA DE PRIVACIDAD"} curPage={"POLÍTICA DE PRIVACIDAD"} />
    <section className="content-section">
      <div className="container">
        <h3 className="title">POLÍTICA DE PRIVACIDAD DE SORTEOS</h3>
        <p className="paragraph">
        Los datos recabados serán tratados por la Asociación Baloncesto 3x3 (La Liga 3x3) con la finalidad de gestionar su
participación en la actividad promocional, inclusive, en su caso, para el envío del premio si resultase como persona
ganadora. Los datos recabados podrán ser tratados por el Responsable del Tratamiento que, en este caso, es Wilson
[datos identificativos*] con la finalidad de actividad promocional. La persona participante declara que los datos
facilitados son exactos y veraces, obligándose a comunicar a La Liga 3x3 cualquier modificación o variación que se
produzca en los mismos.
La base para tratar sus datos con la finalidad de gestionar su participación es la aceptación de las bases legales o
condiciones aplicables y consiguiente participación en la actividad promocional.
En el supuesto de que marque la casilla habilitada a dichos efectos, sus datos personales serán comunicados a Wilson
con la finalidad de que le remitan comunicaciones comerciales sobre los productos y servicios de Wilson por medios
electrónicos sobre la base de su consentimiento otorgado mediante la marcación de dicha casilla. Del mismo modo,
sus datos personales serán tratados por La Liga 3x3 con la finalidad de que le remitan comunicaciones comerciales
sobre los productos y servicios de La Liga 3x3 por medios electrónicos sobre la base de su consentimiento otorgado
mediante la marcación de dicha casilla.
        </p>
        <p className="paragraph">
        Adicionalmente, conforme a lo establecido en las bases legales o condiciones aplicables de la actividad promocional,
en el supuesto de que la Liga 3x3 o Wilson adviertan cualquier indicio de falsedad o fraude en la participación, sus
datos personales serán revisados con la exclusiva finalidad de verificar los datos de participación sobre la base de la
ejecución de dichas bases legales o condiciones aplicables.
Por otro lado, sus datos serán accesibles a aquellos terceros que nos prestan servicios, en la medida en que dicho
acceso sea necesario para la gestión de su participación en la actividad promocional. Dichos terceros han sido
cuidadosamente seleccionados y La Liga 3x3 ha suscrito con éstos los correspondientes contratos de encargado del
tratamiento para garantizar que sus datos se tratan de conformidad con lo dispuesto en la normativa sobre protección
de datos y en la presente política de privacidad. En particular, dichos terceros son prestadores de servicios de
plataformas de gestión de sorteo, esto es, easypromos.
        </p>
        <p className="paragraph">
        Mantendremos mientras que sea necesaria para su actividad promocional la información personal y siempre que no
ejerza su derecho de supresión u oposición al tratamiento de sus datos. La persona participante podrá ejercitar sus
derechos de acceso, rectificación, supresión, oposición, portabilidad y limitación al tratamiento de sus datos, de
conformidad con lo establecido en la legislación vigente a través de su solicitud a laliga@gmail.com.
La Liga 3x3, como encargado del tratamiento de Wilson, se compromete a tratar en todo momento sus datos de
carácter personal de forma absolutamente confidencial y de acuerdo con las finalidades previstas en la presente
cláusula y adoptará las medidas de índole técnica y organizativas necesarias que garanticen la seguridad de sus datos y
eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la
naturaleza de los datos almacenados y los riesgos a que están expuestos.
Si tiene alguna consulta, por favor contacte a través de laliga3x3@gmail.com. Por último, le informamos que tiene
derecho a recabar la tutela de la Agencia Española de Protección de datos a través de su página web www.aepd.es.
        </p>
      <style jsx="true">{`
          /* Contenedor principal */
.content-section {
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 20px auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
}

/* Contenedor interno */
.container {
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Título */
.title {
  font-size: 1.8rem;
  color: #004085;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Párrafos */
.paragraph {
  font-size: 1rem;
  color: #333333;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: justify;
}

        `}</style>
      </div>
    </section>
      <Footer />
  </Fragment>
);