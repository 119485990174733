import { Component, Fragment } from "react";

class SocialMedia extends Component {
    render() {
        return (
            <Fragment>
                <li>
                    <a href="https://www.instagram.com/laliga_3x3/">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/instagram.png`} 
                            alt="Instagram" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
                <li>
                    <a href="https://youtube.com/@laliga_3x3?si=46riwR40JBg7qYQl">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/youtube.png`} 
                            alt="YouTube" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
                <li>
                    <a href="https://www.facebook.com/share/X4h2z12bRwqWLXz4/?mibextid=LQQJ4d">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/facebook.png`} 
                            alt="Facebook" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/company/laliga-3x3/">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/linkedin.png`} 
                            alt="LinkedIn" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
                <li>
                    <a href="https://www.tiktok.com/@laliga_3x3?_t=8q9QTR2Iuv2&_r=1">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/tiktok.png`} 
                            alt="TikTok" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
                <li>
                    <a href="https://x.com/laliga_3x3?s=21">
                        <img 
                            src={`${process.env.PUBLIC_URL}/assets/images/general/twitter.png`} 
                            alt="Twitter" 
                            style={{ width: '30px', height: '30px' }} 
                        />
                    </a>
                </li>
            </Fragment>
        );
    }
}

export default SocialMedia;
