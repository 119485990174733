import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";


class AvisoLegal extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <PageHeader title={'Aviso Legal'} curPage={'Aviso Legal'} />
        <section className="legal-section padding-top padding-bottom">
          <div className="container">
            <div className="section-wrapper">
              <h2>Aviso Legal</h2>
              <p>
                Este sitio web, propiedad de LaLiga3x3, se dedica a la organización de eventos deportivos de baloncesto 3x3 en España. A continuación, detallamos los términos y condiciones que regulan el uso de este sitio.
              </p>
              <h4>1. Titularidad del sitio</h4>
              <p>
                Este sitio web es propiedad de LaLiga3x3, con domicilio en Barcelona, España. Para cualquier consulta, puede ponerse en contacto a través del correo electrónico: laliga3x3@gmail.com.
              </p>
              <h4>2. Propiedad intelectual</h4>
              <p>
                Todo el contenido de este sitio web, incluidos textos, imágenes, logotipos y gráficos, es propiedad de LaLiga3x3 o cuenta con las licencias necesarias para su uso. Queda prohibida su reproducción, distribución o modificación sin autorización expresa.
              </p>
              <h4>3. Uso del sitio</h4>
              <p>
                El usuario se compromete a utilizar este sitio web de forma lícita y respetando los derechos de LaLiga3x3 y de terceros. Queda prohibido el uso de este sitio con fines ilícitos o que puedan perjudicar a terceros.
              </p>
              <h4>4. Enlaces a terceros</h4>
              <p>
                Este sitio puede contener enlaces a sitios web de terceros. LaLiga3x3 no se responsabiliza del contenido ni de las políticas de privacidad de dichos sitios.
              </p>
              <h4>5. Modificaciones</h4>
              <p>
                LaLiga3x3 se reserva el derecho a modificar, actualizar o eliminar los contenidos de este sitio web, así como las presentes condiciones, en cualquier momento y sin previo aviso.
              </p>
              <h4>6. Ley aplicable y jurisdicción</h4>
              <p>
                Estas condiciones se regirán e interpretarán de acuerdo con la legislación española. Para cualquier controversia que pudiera surgir, las partes se someterán a los tribunales de Barcelona, España.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default AvisoLegal;
