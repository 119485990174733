import { Component } from "react";
import { Link } from "react-router-dom";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const subtitle = "EQUIPOS DE LA LIGA";
const title = "Revisa tu equipo favorito";

class EquiposInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      equipos: [],
      loading: true,
    };
  }

  // Llamada a la API para obtener los datos de los equipos
  componentDidMount() {
    fetch("https://laliga3x3.com/backend/api/listadoEquipos.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ equipos: data, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  render() {
    const { equipos, loading } = this.state;
    const baseImageUrl = "https://laliga3x3.com/backend/DOCS/logosEquipos/";

    return (
      <section className="player-section padding-top padding-bottom">
        <div className="container-fluid">
          <div className="section-header">
            <p>{subtitle}</p>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="player-slider">
              {loading ? (
                <p>Cargando equipos...</p>
              ) : (
                <Swiper
                  spaceBetween={10} // Ajustar espacio entre elementos
                  slidesPerView={2}
                  loop={"true"}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    0: {
                      width: 0,
                      slidesPerView: 2, // Mostrar 2 elementos en pantallas pequeñas
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3, // Mostrar 3 elementos en pantallas medianas
                    },
                    1200: {
                      width: 1200,
                      slidesPerView: 4, // Mostrar 4 elementos en pantallas grandes
                    },
                  }}
                >
                  {equipos.map((equipo, i) => (
                    <SwiperSlide key={i}>
                      <div
                        className="player-item-2 text-center"
                        style={{
                          backgroundImage: `url(${baseImageUrl}${equipo.imagenfondo})`,
                          padding: "10px", // Reducir padding del contenedor
                        }}
                      >
                        <div className="player-inner-2">
                          <div className="player-thumb-2">
                            <Link to={`/equipo/${equipo.id}`}>
                              <img
                                src={`${baseImageUrl}${equipo.logo}`}
                                alt={`Logo de ${equipo.nombre}`}
                                style={{
                                  width: "200px", // Reducir ancho de la imagen
                                  height: "200px", // Reducir altura de la imagen
                                }}
                              />
                            </Link>
                          </div>
                          <div className="player-content-2">
                            <Link to={`/equipo/${equipo.id}`}>
                              <h3 style={{ fontSize: "16px" }}>{equipo.nombre}</h3> {/* Reducir tamaño de fuente */}
                            </Link>
                            <ul className="match-social-list d-flex flex-wrap align-items-center justify-content-center">
                              <li>
                                <a href={equipo.instagram}>
                                  <img
                                    src="assets/images/general/insta.png"
                                    alt="instagram"
                                    style={{
                                      width: "30px", // Ajustar tamaño de icono
                                      height: "30px",
                                    }}
                                  />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .player-item-2 {
            padding: 10px; /* Reducir padding del contenedor */
          }

          .player-thumb-2 img {
            width: 200px; /* Reducir ancho de la imagen */
            height: 200px; /* Reducir altura de la imagen */
            object-fit: cover;
            border-radius: 50%; /* Hace la imagen circular */
            margin-bottom: 10px;
          }

          .player-content-2 h3 {
            font-size: 16px; /* Reducir tamaño de fuente del nombre del equipo */
          }
        `}</style>
      </section>
    );
  }
}

export default EquiposInicio;
