import { Component } from "react";
import { Link } from "react-router-dom";
import Rating from "../section/rating";
import SocialMedia from "../section/socialmedia";

const postTitle = "Nuestros Patrocinadores";
const newsTitle = "AVISOS LEGALES";
const desc = "PORQUE EL 3X3 YA NO ES SOLO 9";
const newsdesc = "Bigamer esports organization supported by community leaders";

let FooterItemList = [
  {
    imgUrl: `${process.env.PUBLIC_URL}/assets/images/patrocinadores/logoMomdel1.png`,
    imgAlt: "Logo LaLiga3x3",
    title: "MOMDEL",
    proName: "Poker",
    proPrice: "$230",
    enlace: "https://momdel.es/",
  },
  {
    imgUrl: `${process.env.PUBLIC_URL}/assets/images/patrocinadores/gigantes.png`,
    imgAlt: "Logo Gigantes",
    title: "GIGANTES",
    proName: "Poker",
    proPrice: "$230",
    enlace: "https://www.gigantes.com/",
  },
  //   {
  //     imgUrl: `${process.env.PUBLIC_URL}/assets/images/footer/02.jpg`,
  //     imgAlt: "Footer Blog Post",
  //     title: "CLUB Poker Game",
  //     proName: "Poker",
  //     proPrice: "$230",
  //   },
  //   {
  //     imgUrl: `${process.env.PUBLIC_URL}/assets/images/footer/03.jpg`,
  //     imgAlt: "Footer Blog Post",
  //     title: "ROYAL Poker Game",
  //     proName: "Poker",
  //     proPrice: "$300",
  //   },
];

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsName: "",
      newsEmail: "",
    };
  }
  render() {
    return (
      <footer className="footer-section">
        <div className="footer-top">
          <div className="container">
            <div className="row g-3 justify-content-center g-lg-0">
              {/* <div className="col-lg-4 col-sm-6 col-12">
                <div className="footer-top-item lab-item">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/footer/icons/01.png`}
                        alt="Phone-icon"
                      />
                    </div>
                    <div className="lab-content">
                      <span>Phone Number : +88019 339 702 520</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="footer-top-item lab-item">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/footer/icons/02.png`}
                        alt="email-icon"
                      />
                    </div>
                    <div className="lab-content">
                      <span>Email : laliga3x3@gmail.com</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-12">
                <div className="footer-top-item lab-item">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/footer/icons/03.png`}
                        alt="location-icon"
                      />
                    </div>
                    <div className="lab-content">
                      <span>Localizacion : España</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="footer-middle padding-top padding-bottom"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/footer/bg.jpg)`,
          }}
        >
          <div className="container">
            <div className="row padding-lg-top">
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-middle-item-wrapper">
                  <div className="footer-middle-item mb-lg-0">
                    <div
                      className="fm-item-title mb-4"
                      style={{ width: "116px", height: "100px" }}
                    >
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/general/blanco.png`}
                        alt="logo"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </div>

                    <div className="fm-item-content">
                      <p className="mb-4">{desc}</p>
                      <ul className="match-social-list d-flex flex-wrap align-items-center">
                        <SocialMedia />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-middle-item-wrapper">
                  <div className="footer-middle-item mb-lg-0">
                    <div className="fm-item-title">
                      <h4>{postTitle}</h4>
                    </div>
                    <div className="fm-item-content">
                      {FooterItemList.map((val, i) => (
                        <div className="fm-item-widget lab-item" key={i}>
                          <div className="lab-inner">
                            <div className="lab-thumb">
                              <Link to={val.enlace}>
                                <img
                                  src={`${val.imgUrl}`}
                                  alt={`${val.imgAlt}`}
                                />
                              </Link>
                            </div>
                            <div className="lab-content">
                              <h6>
                                <Link to={val.enlace}>{val.title}</Link>
                              </h6>
                              {/* <p>
                                {val.proName}: <b>{val.proPrice}</b>
                              </p>
                              <Rating /> */}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="footer-middle-item-wrapper">
                  <div className="footer-middle-item-3 mb-lg-0">
                    <div className="fm-item-title">
                      <h4>{newsTitle}</h4>
                    </div>
                    <div className="fm-item-content mt-4">
                      <p>
                        <a
                          href="/PoliticasPrivacidad"
                          className="text-decoration-none"
                        >
                          Políticas de Privacidad
                        </a>
                      </p>
                      <p>
                        <a href="/AvisoLegal" className="text-decoration-none">
                          Aviso Legal
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-bottom-content text-center">
                <p>&copy; 2024 - Derechos reservados por LaLiga3x3 | Designed by <a href="https://momdel.es/" target="_blank" rel="noopener noreferrer">Momdel</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
