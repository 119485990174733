import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

const DetallesEstadisticasTI = () => {
  const { id } = useParams(); // Obtiene el id (nombre de la estadística) de la URL
  const [clasificacion, setClasificacion] = useState([]);
  const [loading, setLoading] = useState(true);

  const formatFieldName = (fieldName) => {
    return fieldName
      .replace(/_/g, ' ')            // Reemplaza guiones bajos por espacios
      .replace(/x/g, ' por ')        // Reemplaza las "x" por "por"
      .replace(/(\d+)/g, ' $1')      // Inserta un espacio antes de los números
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitaliza cada palabra
  };

  // Llamada a la API para obtener los datos de la clasificación según el id
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesEstadisticasTI.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setClasificacion(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [id]);

  // Formato de estadísticas para tiros con anotados e intentados
  const formatStat = (item, field) => {
    const statPercentage = item[field];
    let anotados = null;
    let intentados = null;

    if (field === "tiros1_porcentaje") {
      anotados = item.tiros1anotados;
      intentados = item.tiros1intentados;
    } else if (field === "tiros2_porcentaje") {
      anotados = item.tiros2anotados;
      intentados = item.tiros2intentados;
    } else if (field === "tiroslibre_porcentaje") {
      anotados = item.tiroslibreanotados;
      intentados = item.tiroslibreintentados;
    }

    return (
      <div>
        <span>{anotados !== null && intentados !== null ? `${anotados}/${intentados}` : "N/A"}</span>
        <br />
        <span>{statPercentage !== undefined && statPercentage !== null ? `(${statPercentage}%)` : "N/A"}</span>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <PageHeader title={`ESTADÍSTICAS - ${formatFieldName(id)}`} curPage={'ESTADÍSTICAS'} />
      <section className="player-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header text-center">
            <p className="theme-color text-uppercase ls-2">Clasificación</p>
            <h2>Clasificación por {formatFieldName(id)}</h2>
          </div>
          <div className="section-wrapper d-flex justify-content-center">
            {loading ? (
              <p>Cargando clasificación...</p>
            ) : (
              <table className="clasificacion-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="jugador-column">Jugador</th>
                    <th>Equipo</th>
                    <th>{formatFieldName(id)}</th> 
                  </tr>
                </thead>
                <tbody>
                  {clasificacion.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {index < 3 && item.foto ? (
                          <img 
                            src={`https://laliga3x3.com/backend/DOCS/jugadores/${item.foto}`}
                            alt={`${item.nombre} ${item.apellido}`} 
                            className="player-image" 
                          />
                        ) : null}
                        <Link to={`/jugador/${item.jugador}`} className="team-link">
                          {`${item.nombre} ${item.apellido}`}
                        </Link>
                      </td>
                      <td>{item.nombreEquipo || "N/A"}</td>
                      <td>
                        {(id === "tiros1_porcentaje" || id === "tiros2_porcentaje" || id === "tiroslibre_porcentaje")
                          ? formatStat(item, id)
                          : item[id] !== undefined && item[id] !== null ? item[id] : "N/A"}
                      </td> 
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <style jsx="true">{`
          .player-section {
            padding: 50px 0;
            color: white;
          }

          .section-header p {
            font-size: 18px;
            color: #efb12f;
            margin-bottom: 10px;
          }

          .section-header h2 {
            font-size: 36px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .clasificacion-table {
            width: 100%;
            max-width: 900px;
            background-color: transparent;
            border-collapse: collapse;
            text-align: center;
            color: white;
          }

          .clasificacion-table th,
          .clasificacion-table td {
            padding: 15px;
            border: 1px solid #fff;
          }

          .clasificacion-table th {
            background-color: #efb12f;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }

          .jugador-column {
            width: 50%; /* Aumentando el ancho de la columna Jugador */
          }

          .clasificacion-table tr:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.1);
          }

          .clasificacion-table tr:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          .player-image {
            width: 100px; /* Aumentado el tamaño de la imagen */
            height: 150px; /* Aumentado el tamaño de la imagen */
            border-radius: 50%;
            margin-right: 8px;
          }

          @media (max-width: 768px) {
            .clasificacion-table {
              font-size: 14px;
            }
            .section-header h2 {
              font-size: 28px;
            }
          }
        `}</style>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default DetallesEstadisticasTI;
