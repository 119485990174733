import React, { useState, useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PrimerosClasificados from "../../component/section/mios/PrimerosClasificacion";

const Jornada = () => {
  const { id } = useParams(); // Obtén el ID de la jornada de los parámetros de la URL
  const [jornada, setJornada] = useState({});
  const [loading, setLoading] = useState(true);

  const urlBase = "https://laliga3x3.com/backend/DOCS/jornadas/"; // URL base para las imágenes

  // Fetch jornada details
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesJornada.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setJornada(data[0]); // Acceder al primer elemento del array
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching jornada data: ", error);
      });
  }, [id]);

  const hasSpecialImages = jornada.imagenMvp || jornada.imagenTrio || jornada.imagenFamilia || jornada.imagenCampeones;
  const hasP1Images = jornada.imagenP1 || jornada.imagenP2 || jornada.imagenP3 || jornada.imagenP4;

  const styles = {
    infoSection: {
      paddingTop: '220px', // Añade más padding arriba
      paddingBottom: '60px', // Padding inferior
    },
    mainImage: {
      width: '100%',
      maxHeight: '400px',
      objectFit: 'cover',
      marginBottom: '20px',
    },
    specialImage: {
      width: '100%',
      marginBottom: '15px',
    },
    classificationImage: {
      width: '100%',
      objectFit: 'cover',
      marginBottom: '15px',
    },
    buttonsRow: {
      marginBottom: '20px',
    },
  };

  return (
    <Fragment>
      <Header />

      <div className="about-team" style={styles.infoSection}>
        <div className="container">
          {loading ? (
            <p>Cargando jornada...</p>
          ) : (
            <Fragment>
              <div className="row">
                {/* Sección de información */}
                <div className="col-lg-6">
                  {jornada.imagenPrincipal && (
                    <img
                      src={`${urlBase}${jornada.imagenPrincipal}`}
                      alt="Imagen Principal"
                      style={styles.mainImage}
                    />
                  )}
                </div>
                <div className="col-lg-6">
                  <div className="section-header">
                    <h2 className="mb-3">{jornada.nombre}</h2>
                    <p>{jornada.ubicacion} - {jornada.fecha}</p>
                  </div>
                </div>
              </div>

              {/* Mostrar imágenes MVP, Trio, Familia, Campeones en una cuadrícula de 2x2 */}
              {hasSpecialImages ? (
                <Fragment>
                  <div className="row justify-content-center mt-4">
                    <div className="col-lg-8">
                      <div className="row">
                        {jornada.imagenMvp && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenMvp}`} alt="MVP" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenTrio && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenTrio}`} alt="Trio Ideal" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenFamilia && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenFamilia}`} alt="Familia" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenCampeones && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenCampeones}`} alt="Campeones" style={styles.specialImage} />
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Imagen de Clasificación al lado */}
                    <div className="col-lg-4">
                      <div style={styles.buttonsRow} className="d-flex justify-content-between">
                        <a
                          href={jornada.resultados} 
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ width: '48%' }}
                        >
                          Ver Resultados
                        </a>
                        <Link to={`/imagenesSede/${jornada.id}`}

                          className="btn btn-secondary"
                          style={{ width: '48%' }}
                        >
                          Ver Imagenes
                        </Link>
                      </div>

                      {jornada.imagenClasificacion ? (
                        <img
                          src={`${urlBase}${jornada.imagenClasificacion}`}
                          alt="Clasificación"
                          style={styles.classificationImage}
                        />
                      ) : (
                        <PrimerosClasificados />
                      )}
                    </div>
                  </div>
                </Fragment>
              ) : hasP1Images ? (
                <Fragment>
                  <div className="row justify-content-center mt-4">
                    <div className="col-lg-8">
                      <div className="row">
                        {jornada.imagenP1 && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenP1}`} alt="Imagen 1" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenP2 && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenP2}`} alt="Imagen 2" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenP3 && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenP3}`} alt="Imagen 3" style={styles.specialImage} />
                          </div>
                        )}
                        {jornada.imagenP4 && (
                          <div className="col-md-6">
                            <img src={`${urlBase}${jornada.imagenP4}`} alt="Imagen 4" style={styles.specialImage} />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div style={styles.buttonsRow} className="d-flex justify-content-between">
                        <a
                          href={jornada.horarios}
                          className="btn btn-primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ width: '48%' }}
                        >
                          Ver Horarios
                        </a>
                        <Link
                          to="/noticias" 
                          className="btn btn-secondary"
                          style={{ width: '48%' }}
                        >
                          Ver Noticias
                        </Link>
                      </div>

                      <PrimerosClasificados />
                    </div>
                  </div>
                </Fragment>
              ) : null}
            </Fragment>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Jornada;
