import React, { Component } from "react";
import { Link } from "react-router-dom"; // Importar Link de react-router-dom

class BannerInicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticias: [],
      currentSlide: 0,
      loading: true,
    };
  }

  componentDidMount() {
    // Fetch noticias
    fetch("https://laliga3x3.com/backend/api/listadoNoticias3.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          this.setState({ noticias: data.slice(0, 3), loading: false }); // Take only the first 3 noticias
          this.startCarousel();
        }
      })
      .catch((error) => {
        console.error("Error fetching noticias data: ", error);
      });
  }

  componentWillUnmount() {
    // Clear interval when the component unmounts
    clearInterval(this.carouselInterval);
  }

  startCarousel = () => {
    this.carouselInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentSlide: (prevState.currentSlide + 1) % prevState.noticias.length,
      }));
    }, 5000); // Change slide every 5 seconds
  };

  render() {
    const { noticias, currentSlide, loading } = this.state;

    // Verificar si hay noticias para mostrar
    const currentNoticia = noticias[currentSlide];

    return (
      <section className="banner-section">
        <div className="banner-content-wrapper">
          {/* Left Section for the Logo */}
          <div className="logo-section">
            {currentNoticia && (
              <Link to={`/noticia/${currentNoticia.id}`}>
                <img
                  src={`https://laliga3x3.com/backend/DOCS/noticias/${currentNoticia.imagen1}`}
                  alt="LaLiga3x3 Logo"
                  className="logo-image"
                  style={{ cursor: 'pointer' }} // Cambiar el cursor al pasar por encima
                />
              </Link>
            )}
          </div>

          {/* Right Section for the Text */}
          <div className="text-section">
            {loading ? (
              <p className="banner-title">Cargando...</p>
            ) : (
              currentNoticia && (
                <Link to={`/noticia/${currentNoticia.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <h1 className="banner-title" style={{ cursor: 'pointer' }}>
                    {currentNoticia.titulo}
                  </h1>
                </Link>
              )
            )}
          </div>
        </div>

        {/* Internal CSS */}
        <style jsx="true">{`
          .banner-section {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30vh;
            background-color: #0a0b1e;
            position: relative;
          }

          .banner-content-wrapper {
            display: flex;
            flex-direction: row; /* Colocar contenido en horizontal */
            align-items: center;
            width: 50%; /* Reducir el ancho */
            max-width: 800px; /* Ajustar el ancho máximo */
            padding: 15px;
            border: 2px solid #efb12f;
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
          }

          .logo-section {
            flex: 1; /* Ocupar 1 parte del espacio disponible */
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
          }

          .logo-image {
            max-width: 80%; /* Ajustar logo al contenedor */
            height: auto;
          }

          .text-section {
            flex: 2; /* Ocupar 2 partes del espacio disponible */
            color: #ffffff;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
            padding: 10px;
            display: flex;
            flex-direction: column; /* Mantener disposición vertical de los elementos */
            justify-content: center;
            align-items: center; /* Centrar los elementos horizontalmente */
            text-align: center; /* Centrar el texto */
          }

          .banner-title {
            font-size: 1.2rem; /* Reducir tamaño del texto */
            font-weight: bold;
            margin-bottom: 10px;
          }

          /* Responsive styles */
          @media (max-width: 768px) {
            .banner-section {
              height: 90vh; /* Incrementar la altura en pantallas pequeñas */
              display: flex;
              align-items: flex-start; /* Alinear contenido al inicio */
              padding-top: 20px; /* Espacio superior */
            }

            .banner-content-wrapper {
              flex-direction: column; /* Cambiar a disposición vertical en pantallas pequeñas */
              width: 90%; /* Aumentar el ancho al 90% */
              max-width: 400px; /* Aumentar el ancho máximo */
              padding: 15px; /* Incrementar padding */
              margin-top: 120px; /* Subir el contenedor */
            }

            .logo-section {
              margin-bottom: 10px; /* Reducir el margen inferior para separar la imagen del texto */
            }

            .logo-image {
              max-width: 100%; /* Ajustar el tamaño de la imagen para pantallas pequeñas */
              height: auto;
            }

            .banner-title {
              font-size: 1.1rem; /* Ajustar tamaño del texto */
              margin-bottom: 10px;
            }
          }
        `}</style>
      </section>
    );
  }
}

export default BannerInicio;
