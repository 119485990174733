import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

class PoliticasPrivacidad extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <PageHeader title={'Políticas de Privacidad'} curPage={'Políticas de Privacidad'} />
        <section className="privacy-section padding-top padding-bottom">
          <div className="container">
            <div className="section-wrapper">
              <h2>Políticas de Privacidad</h2>
              <p>
                En LaLiga3x3, respetamos tu privacidad y estamos comprometidos a protegerla. Queremos informarte de que <strong>no recopilamos ningún dato personal</strong> de los usuarios que visitan nuestra página web.
              </p>
              <h4>1. Información que no recopilamos</h4>
              <p>
                Nuestra página web no solicita ni almacena datos personales, como nombres, direcciones de correo electrónico o cualquier otra información identificativa.
              </p>
              <h4>2. Uso de la información</h4>
              <p>
                Dado que no recopilamos datos personales, no utilizamos ninguna información para fines de seguimiento, marketing o contacto.
              </p>
              <h4>3. Enlaces a terceros</h4>
              <p>
                Nuestro sitio web puede contener enlaces a sitios de terceros. LaLiga3x3 no se responsabiliza de las políticas de privacidad ni del contenido de esos sitios. Te recomendamos revisar las políticas de privacidad de cualquier sitio web externo que visites.
              </p>
              <h4>4. Cambios en la Política de Privacidad</h4>
              <p>
                Nos reservamos el derecho de modificar esta política en cualquier momento. Cualquier cambio será reflejado en esta página.
              </p>
              <h4>Contacto</h4>
              <p>
                Si tienes alguna pregunta sobre nuestras políticas de privacidad, puedes contactarnos en: laliga3x3@gmail.com.
              </p>
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default PoliticasPrivacidad;
