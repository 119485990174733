import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";

const ImagenesSede = () => {
  const { id } = useParams();
  const [jornada, setJornada] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch details
  useEffect(() => {
    // Aquí está la URL donde se reciben los datos, usando el ID del parámetro
    fetch(`https://laliga3x3.com/backend/api/detallesImagenesSede.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setJornada(data[0]); // Acceder al primer (y único) elemento del array recibido
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching jornada data: ", error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p>Cargando jornada...</p>;
  }

  if (!jornada) {
    return <p>No se pudo cargar la jornada.</p>;
  }

  const styles = {
    infoSection: {
      paddingTop: "220px",
      paddingBottom: "50px",
      textAlign: "center",
      minHeight: "100vh",
    },
    title: {
      fontSize: "36px",
      fontWeight: "bold",
      marginBottom: "30px",
      color: "#efb12f",
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
    imageGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Imágenes más grandes
      gridGap: "20px",
      justifyItems: "center",
      padding: "0 20px",
    },
    imageItem: {
      width: "100%",
      maxWidth: "400px", // Aumenta el tamaño de las imágenes
      height: "auto",
      objectFit: "cover",
      borderRadius: "15px", // Bordes redondeados más grandes
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)", // Sombra suave
      transition: "transform 0.4s ease, filter 0.4s ease", // Transición suave
      filter: "grayscale(0%)", // Añade un filtro de escala de grises para el hover
    },
  };

  // Función para generar galería de imágenes desde imagen1 hasta imagen20
  const renderGalleryImages = () => {
    const images = [];
    for (let i = 1; i <= 20; i++) {
      const imageKey = `imagen${i}`;
      if (jornada[imageKey]) {
        images.push(
          <img
            key={i}
            src={`https://laliga3x3.com/backend/DOCS/jornadas/${jornada[imageKey]}`}
            alt={`Imagen ${i}`}
            className="gallery-image"
            style={styles.imageItem}
          />
        );
      }
    }
    return images;
  };

  return (
    <Fragment>
      <Header />

      <div className="info-section" style={styles.infoSection}>
        <div className="container">
          <h2 style={styles.title}>Imágenes Sede {jornada.nombre}</h2>

          <div style={styles.imageGrid}>
            {/* Renderiza las imágenes de la galería */}
            {renderGalleryImages()}
          </div>
        </div>
      </div>

      <Footer />

      {/* Estilos CSS para manejar hover sin JavaScript */}
      <style jsx>{`
        .gallery-image {
          transition: transform 0.4s ease, filter 0.4s ease;
        }
        .gallery-image:hover {
          transform: scale(1.05);
          filter: grayscale(0);
        }
      `}</style>
    </Fragment>
  );
};

export default ImagenesSede;
