import { Component } from "react";

class PageHeaderTwo extends Component {
    render() { 
        const { title, imagen } = this.props;
        return (
            <section className="pageheader-section" style={{ backgroundImage: "url(/assets/images/pageheader/bg.jpg)" }}>
                <div className="container">
                    <div className="section-wrapper text-center text-uppercase">
                        <div className="pageheader-thumb mb-4">
                            <img
                                src={`https://laliga3x3.com/backend/DOCS/logosEquipos/${imagen}`}
                                alt={`Logo de ${title}`}
                            />
                        </div>
                        <h2 className="pageheader-title">{title}</h2>
                    </div>
                </div>

                {/* Estilos CSS en la misma página */}
                <style jsx="true">{`
                    .pageheader-thumb img {
                        width: 220px; /* Ancho del logo */
                        height: 220px; /* Alto del logo */
                        object-fit: cover;
                        border-radius: 50%; /* Opcional: hace la imagen circular */
                    }
                `}</style>
            </section>
        );
    }
}
 
export default PageHeaderTwo;
