import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import Sort from "../../component/section/mios/Sorteo";

class Sorteo extends Component {
    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'SORTEO WILSON'} curPage={'SORTEO WILSON'} />
                <Sort />
                <Footer />
            </Fragment>
        );
    }
}
 
export default Sorteo;