import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import PageHeader from "../../component/layout/pageheader";
import Header from "../../component/layout/header";
import Footer from "../../component/layout/footer";

const Noticia = () => {
  const { id } = useParams(); 
  const [noticia, setNoticia] = useState({});
  const [loading, setLoading] = useState(true);
  const baseImageUrl = "https://laliga3x3.com/backend/DOCS/noticias/";

  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesNoticia.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setNoticia(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching noticia data: ", error);
      });
  }, [id]);

  return (
    <Fragment>
      <Helmet>
        <title>{noticia.titulo || "Detalles de la Noticia"}</title>
        <meta name="description" content={noticia.texto ? noticia.texto.substring(0, 150) : "Detalles de la noticia"} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={noticia.titulo} />
        <meta name="twitter:description" content={noticia.texto ? noticia.texto.substring(0, 150) : "Detalles de la noticia"} />
        <meta name="twitter:image" content={`${baseImageUrl}${noticia.imagen1}`} />
        <meta property="og:title" content={noticia.titulo} />
        <meta property="og:description" content={noticia.texto ? noticia.texto.substring(0, 150) : "Detalles de la noticia"} />
        <meta property="og:image" content={`${baseImageUrl}${noticia.imagen1}`} />
        <meta property="og:url" content={`https://laliga3x3.com/noticia/${id}`} />
      </Helmet>

      <Header />
      <PageHeader title={noticia.titulo || "Detalles de la Noticia"} curPage={"NOTICIAS"} />
      <div className="blog-section blog-single padding-top padding-bottom aside-bg">
        <div className="container">
          {loading ? (
            <p className="text-center">Cargando noticia...</p>
          ) : (
            <div className="section-wrapper">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-12">
                  <article className="shadow-sm p-4 bg-white rounded" style={{ marginTop: '20px' }}>
                    <div className="post-item-2">
                      <div className="post-inner">
                        {/* Imagen principal */}
                        <div className="post-thumb mb-4">
                          <img
                            src={`${baseImageUrl}${noticia.imagen1}`}
                            alt="Noticia Imagen 1"
                            className="img-fluid rounded"
                            style={{ maxHeight: '400px', objectFit: 'contain', marginBottom: '20px' }}
                          />
                        </div>

                        {/* Contenido de la noticia */}
                        <div className="post-content text-center">
                          <h1 className="mb-4" style={{ color: '#efb12f', fontSize: '2.5rem', fontWeight: 'bold' }}>
                            {noticia.titulo}
                          </h1>
                          <ul className="lab-ul post-date list-unstyled mb-4">
                            <li>
                              <span style={{ color: '#efb12f' }}>
                                <i className="icofont-ui-calendar"></i> {noticia.fecha}
                              </span>
                            </li>
                          </ul>
                          <hr style={{ borderColor: '#efb12f' }} />
                          <div
                            className="mb-4"
                            style={{ lineHeight: '1.8', textAlign: 'center' }}
                            dangerouslySetInnerHTML={{ __html: noticia.texto }}
                          />

                          {/* Imágenes adicionales */}
                          <div className="post-thumb d-flex justify-content-center gap-3 mt-4 mb-4">
                            <img
                              src={`${baseImageUrl}${noticia.imagen2}`}
                              alt="Noticia Imagen 2"
                              className="img-fluid rounded shadow-sm"
                              style={{ width: '45%', height: 'auto', maxHeight: '300px', objectFit: 'contain', border: `2px solid #efb12f` }}
                            />
                            <img
                              src={`${baseImageUrl}${noticia.imagen3}`}
                              alt="Noticia Imagen 3"
                              className="img-fluid rounded shadow-sm"
                              style={{ width: '45%', height: 'auto', maxHeight: '300px', objectFit: 'contain', border: `2px solid #efb12f` }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Noticia;
