import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";

const Jugador = () => {
  const { id } = useParams(); // Obtén el ID del jugador de los parámetros de la URL
  const [jugador, setJugador] = useState({});
  const [loading, setLoading] = useState(true);

  const urlBase = "https://laliga3x3.com/backend/DOCS/jugadores/"; // URL base para las imágenes de jugadores

  // Fetch player details
  useEffect(() => {
    fetch(`https://laliga3x3.com/backend/api/detallesJugador.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setJugador(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching jugador data: ", error);
      });
  }, [id]);

  const styles = {
    infoSection: {
      paddingTop: '220px', // Añade más padding arriba
      paddingBottom: '60px', // Padding inferior
    },
    playerImage: {
      width: '100%',
      maxWidth: '300px',
      marginBottom: '20px',
      borderRadius: '10px',
      objectFit: 'cover',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    statsBox: {
      backgroundColor: '#f4f4f4',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      marginBottom: '20px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    statsHeader: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#333',
      marginBottom: '10px',
    },
    statsValue: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#000',
    },
  };

  return (
    <Fragment>
      <Header />

      {/* Ficha del Jugador */}
      <div className="about-team" style={styles.infoSection}>
        <div className="container">
          {loading ? (
            <p>Cargando jugador...</p>
          ) : (
            <Fragment>
              <div className="row justify-content-center">
                {/* Imagen del jugador */}
                <div className="col-lg-4 text-center">
                  <img
                    src={`${urlBase}${jugador.imagen}`}  // Imagen del jugador
                    alt={`${jugador.nombre} ${jugador.apellido}`}
                    style={styles.playerImage}
                  />
                </div>

                {/* Detalles del jugador */}
                <div className="col-lg-8">
                  <div className="section-header">
                    <h2 className="mb-3">{jugador.nombre} {jugador.apellido}</h2>
                    <p>Fecha de nacimiento: {jugador.fecha_nacimiento || "Próximamente disponible"}</p>
                    {/* <p>Equipo: {jugador.equipo || "No asignado"}</p> */}
                  </div>

                  {/* Estadísticas del Jugador */}
                  <div className="row">
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>Puntos por Partido</h4>
                        <p style={styles.statsValue}>{jugador.puntosxpartido || "N/A"}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>Asistencias por Partido</h4>
                        <p style={styles.statsValue}>{jugador.asistenciasxpartido || "N/A"}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>Rebotes por Partido</h4>
                        <p style={styles.statsValue}>{jugador.rebotesxpartido || "N/A"}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>% Tiros de 1</h4>
                        <p style={styles.statsValue}>
                          {jugador.tiros1_porcentaje !== undefined ? `${jugador.tiros1_porcentaje}%` : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>% Tiros de 2</h4>
                        <p style={styles.statsValue}>
                          {jugador.tiros2_porcentaje !== undefined ? `${jugador.tiros2_porcentaje}%` : "N/A"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={styles.statsBox}>
                        <h4 style={styles.statsHeader}>% Tiros Libres</h4>
                        <p style={styles.statsValue}>
                          {jugador.tiroslibre_porcentaje !== undefined ? `${jugador.tiroslibre_porcentaje}%` : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>

      <Footer />

      {/* Estilos específicos */}
      <style jsx="true">{`
        .stats-box:hover {
          transform: translateY(-10px);
        }
      `}</style>
    </Fragment>
  );
};

export default Jugador;
