import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";
import GoogleMap from "../../component/section/googlemap";


const infosubtitle = "COPA 3X3";
const infotitle = "En construcción";
const contacttitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";


let infoListContent = [
    {
        imgUrl: 'assets/images/contact/icon/01.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Office Address',
        desc: '1201 park street, Fifth Avenue',
    },
    {
        imgUrl: 'assets/images/contact/icon/02.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Phone number',
        desc: '+22698 745 632, 02 982 745',
    },
    {
        imgUrl: 'assets/images/contact/icon/03.png',
        imgAlt: 'Contact Info Thumb',
        title: 'Send Email',
        desc: 'yourmail@gmail.com',
    },
]

class Copa3x3 extends Component {
    constructor(props){
        super(props);
        this.state = {
            contactName: '',
            contactEmail: '',
            contactSubject: '',
            contactNumber: '',
            contactMassage: '',
        };
    }

    render() { 
        return (
            <Fragment>
                <Header />
                <PageHeader title={'COPA3X3'} curPage={'COPA3X3'} />
                <div className="info-section padding-top padding-bottom">
                    <div className="container">
                        <div className="section-header">
                            <p>{infosubtitle}</p>
                            <h2>{infotitle}</h2>
                        </div>
                        {/* <div className="section-wrapper">
                            <div className="row justify-content-center g-4">
                                {infoListContent.map((val, i) => (
                                    <div className="col-lg-4 col-sm-6 col-12" key={i}>
                                        <div className="contact-item text-center">
                                            <div className="contact-thumb mb-4">
                                                <img 
                                                    src={`${val.imgUrl}`} 
                                                    alt={`${val.imgAlt}`} 
                                                />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                    </div>
                </div>


                <Footer />
            </Fragment>
        );
    }
}
 
export default Copa3x3;