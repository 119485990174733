import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeader from "../../component/layout/pageheader";

class Noticia2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticias: [],
    };
  }

  componentDidMount() {
    // Realizar la solicitud a la API
    fetch("https://laliga3x3.com/backend/api/listadoNoticias.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ noticias: data });
      })
      .catch((error) => console.error("Error al obtener las noticias:", error));
  }

  render() {
    const { noticias } = this.state;
    const baseImageUrl = "https://laliga3x3.com/backend/images/"; // Base URL de las imágenes

    return (
      <Fragment>
        <Header />
        <PageHeader title={"NOTICIAS"} curPage={"NOTICIAS"} />
        <div className="blog-section padding-top padding-bottom">
          <div className="container">
            <div className="section-wrapper">
              <div className="row g-4 justify-content-center">
                {noticias.map((noticia, i) => (
                  <div className="col-lg-4 col-md-6 col-12" key={i}>
                    <div className="blog-item">
                      <div className="blog-inner">
                        <div className="blog-thumb">
                        <Link
                            to={`/noticia/${noticia.id}`}
                          >
                            <img
                              src={`https://laliga3x3.com/backend/DOCS/noticias/${noticia.imagen1}`}
                              alt={`Imagen de ${noticia.titulo}`}
                              className="w-100"
                            />
                          </Link>
                        </div>
                        <div className="blog-content px-3 py-4">
                        <Link
                            to={`/noticia/${noticia.id}`}
                          >
                            <h3>{noticia.titulo}</h3>
                          </Link>
                          <div className="meta-post">
                            <a href="#">{noticia.fecha}</a>
                          </div>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: noticia.textoCorto,
                            }}
                          />
                          <Link
                            to={`/noticia/${noticia.id}`}
                            className="default-button"
                          >
                            <span>
                              Leer Más <i className="icofont-circled-right"></i>
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Noticia2;
