import React, { Component } from "react";
import { Link } from "react-router-dom"; // Importar Link desde react-router-dom

const subtitle = "Clasificación";
const title = "Clasificación de Equipos";

class Cla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clasificacion: [], // Estado para almacenar los datos de la clasificación
      loading: true, // Estado de carga
    };
  }

  // Llamada a la API para obtener los datos de la clasificación
  componentDidMount() {
    fetch("https://laliga3x3.com/backend/api/Clasificacion.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ clasificacion: data, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  render() {
    const { clasificacion, loading } = this.state;

    return (
      <section className="player-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header text-center">
            <p className="theme-color text-uppercase ls-2">{subtitle}</p>
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper d-flex justify-content-center">
            {loading ? (
              <p>Cargando clasificación...</p>
            ) : (
              <table className="clasificacion-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Equipo</th>
                    <th>Puntos</th>
                  </tr>
                </thead>
                <tbody>
                  {clasificacion.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link to={`/equipo/${item.id}`} className="team-link">
                          {item.nombre}
                        </Link>
                      </td>
                      <td>{item.puntos}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <style jsx="true">{`
          .player-section {
            padding: 50px 0;
            color: white;
          }

          .section-header p {
            font-size: 18px;
            color: #efb12f;
            margin-bottom: 10px;
          }

          .section-header h2 {
            font-size: 36px;
            color: #fff;
            font-weight: bold;
            margin-bottom: 20px;
          }

          .clasificacion-table {
            width: 100%;
            max-width: 900px;
            background-color: transparent;
            border-collapse: collapse;
            text-align: center;
            color: white;
          }

          .clasificacion-table th,
          .clasificacion-table td {
            padding: 15px;
            border: 1px solid #fff;
          }

          .clasificacion-table th {
            background-color: #efb12f;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }

          .clasificacion-table tr:nth-child(even) {
            background-color: rgba(255, 255, 255, 0.1);
          }

          .clasificacion-table tr:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }

          .team-link {
            color: #efb12f;
            text-decoration: none;
            font-weight: bold;
          }

          .team-link:hover {
            color: #fff;
          }

          @media (max-width: 768px) {
            .clasificacion-table {
              font-size: 14px;
            }
            .section-header h2 {
              font-size: 28px;
            }
          }
        `}</style>
      </section>
    );
  }
}

export default Cla;
