import React, { Component } from "react";
import { Link } from "react-router-dom"; // Importar Link de react-router-dom

const subtitle = "Consulta el Calendario";
const title = "Próximas Jornadas y Sedes";

class Cal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calendario: [],
      loading: true,
    };
  }

  componentDidMount() {
    fetch("https://laliga3x3.com/backend/api/listadoJornadas.php")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ calendario: data, loading: false });
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }

  render() {
    const { calendario, loading } = this.state;

    // Define the pattern for displaying cards in rows
    const cardPattern = [3, 2, 1, 2, 3, 1];

    // Helper to distribute the cards based on the pattern
    const getCardRows = () => {
      let rows = [];
      let index = 0;

      cardPattern.forEach((count) => {
        rows.push(calendario.slice(index, index + count));
        index += count;
      });

      return rows;
    };

    return (
      <section className="player-section padding-top padding-bottom">
        <div className="container">
          <div className="section-header text-center">
            <h2>{title}</h2>
          </div>
          <div className="section-wrapper">
            {loading ? (
              <p>Cargando calendario...</p>
            ) : (
              getCardRows().map((row, rowIndex) => (
                <div className="card-row" key={rowIndex}>
                  {row.map((evento, cardIndex) => (
                    <Link
                      to={`/jornada/${evento.id}`}
                      className="card-item"
                      key={cardIndex}
                    >
                      <img
                        src={`https://laliga3x3.com/backend/DOCS/jornadas/${evento.imagenPrincipal}`}
                        alt={`Jornada ${evento.jornada}`}
                        className="card-image"
                      />
                    </Link>
                  ))}
                </div>
              ))
            )}
          </div>
        </div>

        {/* Estilos CSS en la misma página */}
        <style jsx="true">{`
          .card-row {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
          }

          .card-item {
            border: 2px solid #efb12f;
            border-radius: 10px;
            overflow: hidden;
            margin: 0 20px;
            transition: transform 0.3s ease;
            display: block; /* Asegura que el link sea un bloque clicable */
          }

          .card-item:hover {
            transform: scale(1.05);
          }

          .card-image {
            width: 100%;
            height: 250px;
            object-fit: cover;
          }

          @media (max-width: 768px) {
            .card-row {
              flex-wrap: wrap;
            }

            .card-item {
              flex: 1 1 100%;
              margin: 15px 0;
              height: 300px;
            }

            .card-image {
              height: 100%;
            }
          }
        `}</style>
      </section>
    );
  }
}

export default Cal;
