import { Component } from "react";
import { NavLink, Link } from "react-router-dom";

const contactNumber = "laliga3x3@gmail.com";
const contactAddress = "España";

let SocialMideaList = [
  {
    IconName: "icofont-instagram",
    IconLink: "https://www.instagram.com/laliga_3x3/",
  },
  {
    IconName: "icofont-twitter",
    IconLink: "https://x.com/laliga_3x3?s=21",
  },
  {
    IconName: "icofont-youtube",
    IconLink: "https://www.youtube.com/@LaLiga_3x3",
  },
  // {
  //   IconName: "icofont-tiktok",
  //   IconLink: "https://www.youtube.com/@LaLiga_3x3",
  // },
  {
    IconName: "icofont-facebook",
    IconLink: "https://www.facebook.com/share/X4h2z12bRwqWLXz4/?mibextid=LQQJ4d",
  },
  {
    IconName: "icofont-linkedin",
    IconLink: "https://www.linkedin.com/company/laliga-3x3/",

  },
];

class Header extends Component {
  menuTrigger() {
    document.querySelector(".menu").classList.toggle("active");
    document.querySelector(".header-bar").classList.toggle("active");
  }
  menuTriggerTwo() {
    document.querySelector(".header-top").classList.toggle("open");
  }

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 200) {
        document
          .querySelector(".header-section")
          .classList.add(["header-fixed"], ["fadeInUp"]);
      } else {
        document
          .querySelector(".header-section")
          .classList.remove(["header-fixed"], ["fadeInUp"]);
      }
    });

    return (
      <header className="header-section">
        <div className="container">
          <div className="header-holder d-flex flex-wrap justify-content-between align-items-center">
            <div className="brand-logo d-none d-lg-inline-block">
              <div className="logo">
                <Link to="/">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/general/blanco.png`}
                    alt="logo"
                    style={{ width: "115px", height: "90px" }}
                  />
                </Link>
              </div>
            </div>
            <div className="header-menu-part">
              <div className="header-top">
                <div className="header-top-area">
                  <ul className="left">
                    <li>
                      <i className="icofont-ui-email"></i> <span>{contactNumber}</span>
                    </li>
                    <li>
                      <i className="icofont-location-pin"></i> {contactAddress}
                    </li>
                  </ul>
                  <ul className="social-icons d-flex align-items-center">
                    {SocialMideaList.map((val, i) => (
                      <li key={i}>
                        <a href={`${val.IconLink}`} className="fb">
                          <i className={`${val.IconName}`}></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="header-bottom">
                <div className="header-wrapper justify-content-lg-end">
                  <div className="mobile-logo d-lg-none">
                    <Link to="/">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/general/blanco.png`}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="menu-area">
                    <ul className="menu">
                      <li>
                        <NavLink to="/">LALIGA3X3</NavLink>
                      </li>
                      <li>
                        <NavLink to="/calendario">CALENDARIO</NavLink>
                      </li>
                      <li>
                        <NavLink to="/clasificacion">CLASIFICACION</NavLink>
                      </li>
                      {/* <li className="menu-item-has-children">
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-bs-offset="0,0"
                        >
                          EQUIPOS
                        </a>
                        <ul className="submenu dropdown-menu">
                          <li>
                            <NavLink to="/equipos">EQUIPOS</NavLink>
                          </li>
                          <li>
                            <NavLink to="/Estadisticas">ESTADISTICAS</NavLink>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <NavLink to="/equipos">EQUIPOS</NavLink>
                      </li>
                      <li>
                        <NavLink to="/estadisticas">ESTADISTICAS</NavLink>
                      </li>
                      <li>
                        <NavLink to="/noticias">NOTICIAS</NavLink>
                      </li>
                      {/* <li>
                        <NavLink to="/Copa3x3">COPA 3X3</NavLink>
                      </li> */}
                    </ul>
                    <div
                      className="header-bar d-lg-none"
                      onClick={this.menuTrigger}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <div
                      className="ellepsis-bar d-lg-none"
                      onClick={this.menuTriggerTwo}
                    >
                      <i className="icofont-info-square"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
